
/* global H5P */
/* global PBxAPI */

(function (PBxApiHandler) {
	/**
	 * Sends xAPI statements to the server
	 * @param {H5P.XAPIEvent} event
	 */
	PBxApiHandler.sendXApiStatement = function (event) {
		const verb = event.getVerb();

		if (!['completed', 'answered'].includes(verb)) {
			return;
		}

		// Avoid sending child answered questions for compound activities
		if (event.getVerifiedStatementValue(['context', 'contextActivities', 'parent'])) {
			return;
		}

		const data = new FormData();

		data.append('post_id', PBxAPI.post_id);
		data.append('h5p_id', event.getVerifiedStatementValue(['object', 'definition', 'extensions', 'http://h5p.org/x-api/h5p-local-content-id']));
		data.append('score', event.getScore());
		data.append('max_score', event.getMaxScore());
		data.append('statement', JSON.stringify(event.data.statement));

		fetch(PBxAPI.url, {
			method: 'POST',
			body: data,
		})
			.then(response => response.json())
			.catch(error => console.error('error:', error));
	};

	/**
	 * Initializes the xAPI event listener
	 */
	PBxApiHandler.init = function () {
		document.addEventListener('DOMContentLoaded', function () {
			if (typeof H5P === 'object') {
				H5P.externalDispatcher.on('xAPI', PBxApiHandler.sendXApiStatement);
			}
		});
	};

})(window.PBxApiHandler = window.PBxApiHandler || {});

PBxApiHandler.init();
